/* eslint-disable max-len */
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../Themes'
import Text from '../Text'
import { Arrow, Robot2 } from '../../Themes/Images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { WHAT_DP_CAN_DO } from '../../Utils/HomePageData'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

const articleStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    background: colors.white,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // gap: 65,
    paddingBlock: 70,
    marginInline: 'auto',
    maxWidth: 1100,
    flexDirection: 'column',
    marginTop: 15,
    [theme.breakpoints.up(1921)]: {
      maxWidth: 1300,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(1440)]: {
      maxWidth: 1000,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: 900,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 700,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(760)]: {
      maxWidth: '100%',
      flexDirection: 'column',
      // gap: 20,
      paddingInline: 16,
      paddingBlock: 40,
    },
  },
  goBack: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 3,
    cursor: 'pointer'
  },
  arrow: {
    transform: 'rotate(180deg)',
    width: 24,
    height: 24,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  mainTitle: {
    display: 'flex',
    maxWidth: 700,
    textAlign: 'center',
    marginBottom: 24,
    marginTop: 56,
    [theme.breakpoints.down(1280)]: {
      maxWidth: 600,
    },
  },
  mainDescription: {
    display: 'flex',
    maxWidth: 800,
    textAlign: 'center',
    marginBottom: 48
  },
  mainImage: {
    width: '100%',
    display: 'flex',
    marginBottom: 58,
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain'
  },
  contentHolder: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32
  },
  articleText: {
    color: '#555', 
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    }
  }
}))

const ArticleComponent = ({ item }) => {
  const classes = articleStyles()

  return (
    <>
      <div className={classes.mainTitle}>
        <Text weight='medium' size='h2'>{item.mainTitle}</Text>
      </div>
      <div className={classes.mainDescription}>
        <Text color='#555' size='h5'>{item.mainDescription}</Text>
      </div>
      <div className={classes.mainImage}>
        <img  style={{ width: '100%', height: 'auto', objectFit: 'contain' }} loading='lazy' alt={item.mainTitle} src={item.src}></img>
      </div>
      <div className={classes.contentHolder}>
        <Text weight='medium' size='h5'>{item.title1}</Text>
        <div className={classes.articleText} style={{  }} dangerouslySetInnerHTML={{ __html: item.description3 }} />
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description4 }} />
        <Text weight='medium' size='h5'>{item.title2}</Text>
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description5 }} />
        <Text weight='medium' size='h5'>{item.title3}</Text>
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description6 }} />
        <Text weight='medium' size='h5'>{item.title4}</Text>
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description7 }} />
        <Text weight='medium' size='h5'>{item.title5}</Text>
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description8 }} />
        <Text weight='medium' size='h5'>{item.title6}</Text>
        <div className={classes.articleText}  dangerouslySetInnerHTML={{ __html: item.description9 }} />
      </div>
    </>
  )
}

const Article = () => {
  const { articleId } = useParams()
  const history = useHistory()

  const article = WHAT_DP_CAN_DO.find((item) => item.id === parseInt(articleId))

  const goBack = () => history.goBack('/')

  if (!article) {
    return <p>Article not found</p>
  }
  const classes = articleStyles()
  return (
    <section className={classes.mainWrapper}>
      <div className={classes.goBack} onClick={goBack}>
        <Arrow className={classes.arrow} />
        <Text size='body'>Back</Text>
      </div>
      <ArticleComponent item={article} />
    </section>
  )
}

export default Article