/* eslint-disable max-len */
import React from 'react'
import { PMGH } from '../Themes/Images'

const WHAT_DP_CAN_DO = [
  {
    id: 1,
    text: 'Seamless Software Integration',
    mainTitle: 'Seamless Software Integration',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about2/Rrrota.svg',
    mainDescription: 'Transform Your Reports into Actionable Insights',
    desc: 'Connect effortlessly with Cliniko, Nookal, or XERO to create a comprehensive data ecosystem.\n' +
      'This integration streamlines workflows and ensures all your data is centralised,boosting team collaboration and efficiency.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/software.svg',
    title1: 'Why You’ll Love Integrating with Dataplayer',
    description3: '<ul>' +
      '<li><b>Visualise Data Effortlessly:</b> Clear, intuitive charts and graphs.</li>' +
      '<li><b>Customise Reports:</b> Tailor reports to fit your unique practice needs.</li>' +
      '<li><b>Centralised Data:</b>  Integrate multiple systems into one platform for streamlined management.</li>' +
      '</ul>',
    description4: 'Managing a healthcare practice involves juggling various data types—appointments, patient records, finances, and more. While systems like Cliniko, Nookal, and XERO come with their own reporting features, integrating Dataplayer can significantly enhance how you handle your data. Here’s how Dataplayer’s seamless integration makes your reports more effective and user-friendly.',
    title2: 'Visualise Your Data Effortlessly',
    description5: 'Dataplayer excels at turning complex data into clear, engaging visuals. Although built-in reports from your practice management systems are useful, they can sometimes be hard to interpret. Dataplayer’s visualisation tools simplify understanding:' +
      '<ul>' +
      '<li><b>Clear, Intuitive Visuals: </b> Transform raw data into easy-to-read charts, graphs, and dashboards. This visual approach helps you quickly identify trends and patterns without wading through dense numbers.</li>' +
      '<li><b>Enhanced Data Comprehension: </b> Visual representations make it easier to digest complex information. Whether reviewing patient outcomes, financial performance, or appointment trends, our visual tools ensure you grasp essential insights at a glance.</li>' +
      '</ul>',
    title3: 'Customise Reports to Fit Your Needs',
    description6: 'Every healthcare practice has unique requirements, and your reports should reflect that. Dataplayer allows you to customise your reports precisely:' +
      '<ul>' +
      '<li><b>Bespoke Reporting: </b> Create reports tailored to your specific needs. Filter data, choose relevant metrics, and design reports that answer your most important questions.</li>' +
      '<li><b>Automated Calculations: </b> Eliminate manual number crunching. Dataplayer automates the reporting process, providing ready-to-use reports that save you time and reduce the risk of errors.</li>' +
      '</ul>',
    title4: 'Share Ready-Made Reports with Your Team',
    description7: 'Effective teamwork is crucial in healthcare settings, and Dataplayer makes it easy to share insights with your colleagues:' +
      '<ul>' +
      '<li><b>Instant Sharing: </b> Generate reports that are ready to be shared with your team at the click of a button. No more exporting data or creating complex presentations—our platform simplifies the process.</li>' +
      '<li><b>Collaborative Insights: </b> With all your data in one place, your team can access the same up-to-date information. This shared access improves coordination and facilitates better decision-making.</li>' +
      '</ul>',
    title5: 'Bring All Your Systems Together',
    description8: 'Integrating Dataplayer ensures that Cliniko, Nookal, XERO, and other systems work seamlessly together:' +
      '<ul>' +
      '<li><b>Centralised Data: </b>Instead of switching between multiple platforms, Dataplayer consolidates all your data into one central location. This integration makes managing and analysing information more straightforward.</li>' +
      '<li><b>Streamlined Workflows: </b>  With everything integrated, you spend less time managing different systems and more time focusing on patient care. The seamless connection ensures that your data remains synchronised and current.</li>' +
      '</ul>',
    title6: 'In Summary',
    description9: 'Dataplayer’s seamless software integration isn’t just about connecting systems—it’s about transforming your data management. By providing intuitive visualisations, customisable reports, and easy sharing, Dataplayer enhances how you interact with your practice’s data. Integrate Dataplayer today to experience a more efficient, insightful way to handle your healthcare data.',
  },
  {
    id: 2,
    text: 'Real-Time Data Insights',
    mainTitle: 'Real-Time Data Insights',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about/Group%209334.svg',
    mainDescription: ' Stay Ahead with Updated Information',
    desc: 'Data is updated hourly! Stay on top of performance metrics and respond quickly to changes with real-time insights.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/data.svg',
    title1: 'Why Real-Time Data Insights Matter:',
    description3: '<ul>' +
      '<li><b>Stay Current:</b> With hourly updates, your data is always fresh, helping you make decisions based on the latest information.</li>' +
      '<li><b>Monitor Performance:</b>Track key metrics as they evolve, allowing for proactive management of your practice.</li>' +
      '<li><b>Act Quickly:</b> Address issues promptly with up-to-date insights, enhancing your practice’s responsiveness and efficiency.</li>' +
      '</ul>',
    description4: 'In the fast-paced world of healthcare, timely information is crucial for making informed decisions. Dataplayer ensures you’re always in the loop with data updates. Here’s how it keeps you ahead:',
    title2: 'Hourly Data Updates',
    description5: 'With Dataplayer, your data isn’t static—it’s dynamic. Our platform updates your information every hour, so you’re always working with the most current data available. This frequent refreshing ensures that you’re never behind on important metrics or trends.',
    title3: 'Instant Performance Metrics',
    description6: 'Stay on top of your practice’s performance with real-time metrics. Whether you’re tracking patient outcomes, financial performance, or operational efficiency, Dataplayer provides immediate access to key indicators. This means you can monitor how your practice is performing throughout the day, without waiting for end-of-day reports.',
    title4: 'Quick Response to Changes',
    description7: 'In a busy healthcare environment, being able to respond quickly to changes is essential. Dataplayer’s real-time insights allow you to identify and address issues as they arise. If a metric shows a sudden shift, you can take immediate action to resolve it, ensuring your practice remains on track and efficient.',
    title5: 'In Summary',
    description8: 'Dataplayer ensures you’re always up-to-date with hourly data updates and instant performance metrics. By providing current information and allowing for quick responses to changes, Dataplayer helps you manage your practice more effectively.',

  },
  {
    id: 3,
    text: 'Customised KPI Reports',
    mainTitle: 'Customised KPI Reports',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about/Group%209340.svg',
    mainDescription: 'Tailor Your Insights to What Matters Most',
    desc: 'Generate and customise reports focusing on the key performance indicators that matter most. Easily share insights with your team to keep everyone aligned with your goals.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/kpi.svg',
    title1: 'Why Customisable KPI Reports Matter:',
    description3: '<ul>' +
      '<li><b>Focused Insights: </b> Generate reports that are specifically tailored to the metrics that matter most, giving you more relevant and actionable insights.</li>' +
      '<li><b>Flexibility and Control: </b> Customise your reports to fit your unique needs, ensuring you get the data presented in a way that makes the most sense for your practice.</li>' +
      '<li><b>Improved Collaboration:</b> Share insights effortlessly with your team, keeping everyone informed and aligned with your practice’s goals.</li>' +
      '</ul>',
    description4: 'In today’s data-driven world, having reports that align with your specific needs is essential for effective practice management. Dataplayer allows you to focus on what truly matters, ensuring that you and your team are always on the same page. Here’s how it works and why it’s so beneficial.',
    title2: 'Generate Custom Reports',
    description5: 'Dataplayer lets you create reports centred on the Key Performance Indicators (KPIs) that are most relevant to your practice. Whether you’re interested in tracking patient satisfaction, financial performance, or appointment efficiency, our platform gives you the flexibility to focus on these crucial metrics. Instead of dealing with generic reports, you can generate customised reports that provide insights directly aligned with your practice’s goals.',
    title3: 'Tailor to Your Needs',
    description6: 'Every practice is unique, and so are its reporting needs. With Dataplayer, you can tailor your reports to fit your specific requirements:' +
      '<ul>' +
      '<li><b>Select Relevant KPIs: </b>Choose which performance indicators you want to include in your reports. This means you only see the data that’s important to you.' +
      '<li><b>Custom Layouts: </b> Adjust the layout and format of your reports to highlight key trends and insights. Whether you prefer charts, graphs, or tables, you can customise your reports to present data in the most effective way for your practice.</li>' +
      '<li><b>Filter and Drill Down:</b> Use filters to drill down into specific data points and trends. This allows you to get a deeper understanding of the metrics that matter most and make more informed decisions.</li>' +
      '</ul>',
    title4: 'Effortless Sharing',
    description7: 'Once your custom reports are ready, sharing them with your team is simple:' +
      '<ul>' +
      '<li><b>Instant Distribution: </b>Distribute reports with just a few clicks. No more manual exporting or complex formatting—Dataplayer streamlines the sharing process, so your team receives the latest insights quickly.' +
      '<li><b>Alignment with Goals: </b>  By sharing customised KPI reports, you ensure that everyone in your practice is aligned with your goals. This transparency helps foster a collaborative environment where all team members are working towards the same objectives.</li>' +
      '</ul>',
    title5: 'In Summary',
    description8: 'Dataplayer’s Customisable KPI Reports feature allows you to generate and tailor reports focusing on the performance indicators that are crucial to your practice. With the ability to customise, filter, and share insights easily, you can ensure that you and your team stay aligned with your goals and make informed decisions based on the most relevant data. Integrate with Dataplayer today to take advantage of customised reports that enhance your practice’s performance and teamwork.',

  },
  {
    id: 4,
    text: 'Target Setting and Tracking',
    mainTitle: 'Target Setting and Tracking',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about/Group%209341.svg',
    mainDescription: 'Streamline Your Path to Success',
    desc: 'Set precise business goals and monitor progress with ease. Adjust strategies as needed to drive success and growth.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/goal.svg',
    title1: 'Why Goal Setting and Progress Tracking Matter:',
    description3: 'Achieving success in your healthcare practice isn’t just about treating patients; it’s about setting clear goals and monitoring your progress. Dataplayer’s Goal Setting and Progress Tracking feature makes this process smooth and straightforward. Here’s why it’s essential:' +
      '<ul>' +
      '<li><b>Clear Objectives: </b> Set specific, actionable goals for your practice to drive focus and motivation.</li>' +
      '<li><b>Easy Monitoring: </b> Track your progress effortlessly, so you always know how close you are to reaching your targets.</li>' +
      '<li><b>Adaptive Strategies: </b> Adjust your plans based on real-time data to ensure continuous growth and success.</li>' +
      '</ul>',
    title2: 'Set Precise Goals',
    description5: 'To reach your goals, you first need to define them. Dataplayer makes setting goals simple and effective. Whether you aim to increase patient numbers, enhance service quality, or boost revenue, you can set clear and precise objectives tailored to your practice’s needs.' +
      '<ul>' +
      '<li><b>Define Your Targets: </b>Set specific goals like “have 200 patient visits ” or “reduce appointment no-shows to 15.”' +
      '<li><b>Visualise Success: </b> Our platform helps you break down these goals into manageable milestones, making it easier to track and achieve them.</li>' +
      '</ul>',
    title3: 'Monitor Progress with Ease',
    description6: 'Once your goals are in place, monitoring your progress should be effortless. Dataplayer provides a clear view of how you’re doing with intuitive dashboards and real-time updates. This means you can focus on what matters most without getting bogged down in complicated data.' +
      '<ul>' +
      '<li><b>Real-Time Updates: </b>See your progress at a glance with up-to-date information.' +
      '<li><b>Visual Dashboards: </b> Use easy-to-read charts and graphs to understand how your practice is performing against your goals.</li>' +
      '</ul>',
    title4: 'Adjust Strategies as Needed',
    description7: 'Sometimes, despite your best efforts, things don’t go as planned. That’s why it’s important to be able to adjust your strategies based on current data. Dataplayer helps you identify what’s working and what’s not, so you can make informed changes to stay on track.' +
      '<ul>' +
      '<li><b>Data-Driven Decisions: </b>Get actionable insights from your data to understand where adjustments are needed.' +
      '<li><b>Flexible Strategies: </b> Adapt your approach based on real-time feedback to drive continued success.</li>' +
      '</ul>',
    title5: 'Drive Success and Growth',
    description8: 'Ultimately, Goal Setting and Progress Tracking with Dataplayer are all about helping your practice succeed and grow. By setting clear goals and easily tracking your progress, you can ensure that your practice is always moving in the right direction.' +
      '<ul>' +
      '<li><b>Achieve Your Objectives: </b>Stay focused on your targets with clear and actionable insights.' +
      '<li><b>Foster Growth: </b>Use the data to identify opportunities for expansion and improvement.</li>' +
      '</ul>',
    title6: 'In Summary',
    description9: 'Dataplayer’s Goal Setting and Progress Tracking feature takes the guesswork out of managing your practice’s goals. By making it simple to set precise objectives, monitor progress effortlessly, and adjust strategies as needed, you can drive success and foster growth with confidence. Integrate Dataplayer into your practice today and experience how straightforward goal management can be.',
  },
  {
    id: 5,
    text: 'Advanced Analytics with AI',
    mainTitle: 'Advanced Analytics with AI',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about/Group%209343.svg',
    mainDescription: 'Elevate Your Practice with Intelligent Insights',
    desc: 'Leverage machine learning and AI to analyse complex data and uncover actionable insights. Advanced analytics support better forecasting and smarter decision-making.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/ai.svg',
    title1: 'Why Advanced Analytics with AI Matters',
    description3: 'In the fast-paced world of healthcare, making informed decisions is crucial. Dataplayer’s Advanced Analytics with AI harnesses the power of machine learning to turn complex data into actionable insights. This feature is designed to support smarter decision-making and more accurate forecasting, all through our intuitive AI Assistant. Here’s how it can transform your practice:' +
      '<ul>' +
      '<li><b>Intelligent Insights: </b> Uncover deep insights from your data that are often missed with traditional methods.</li>' +
      '<li><b>Smarter Forecasting: </b> Use AI-driven predictions to plan for the future with greater confidence.</li>' +
      '<li><b>Enhanced Decision-Making: </b> Make better decisions based on comprehensive, data-driven analysis.</li>' +
      '</ul>',
    title2: 'Leverage AI for Deeper Insights',
    description5: 'Understanding your practice’s data can be overwhelming, but with Dataplayer’s AI Assistant, it becomes much simpler. Our AI-powered tool uses advanced machine learning algorithms to analyse vast amounts of data quickly and accurately. This means you get insights that go beyond basic trends and patterns.' +
      '<ul>' +
      '<li><b>Data Analysis: </b>The AI Assistant examines your data from multiple angles, revealing hidden insights and trends.' +
      '</ul>',
    title3: 'Forecast with Confidence',
    description6: 'Accurate forecasting is key to planning and growing your practice. Dataplayer’s AI Assistant uses predictive analytics to provide forecasts that are based on historical data and current trends. This means you can plan for future needs, patient flow, and financial performance with a higher degree of accuracy.' +
      '<ul>' +
      '<li><b>Predictive Models: </b>Benefit from advanced forecasting models that predict future trends and outcomes.' +
      '<li><b>Strategic Planning: </b> Use these insights to plan effectively for changes, expansions, and other strategic decisions.</li>' +
      '</ul>',
    title4: 'Make Smarter Decisions',
    description7: 'Decision-making is often the hardest part of managing a practice. Dataplayer’s AI Assistant simplifies this by providing you with detailed analyses and recommendations. Instead of sifting through mountains of data yourself, you can rely on AI to deliver precise, relevant insights that guide your decisions.' +
      '<ul>' +
      '<li><b>Natural Language Processing (NLP): </b>Interact with the AI Assistant using simple, conversational language. Ask questions about your data, and get straightforward answers and insights.' +
      '<li><b>Real-Time Support: </b> Receive instant feedback and recommendations, helping you stay on top of your practice’s performance without delay.</li>' +
      '</ul>',
    title5: 'Drive Success and Growth',
    description8: 'Ultimately, Goal Setting and Progress Tracking with Dataplayer are all about helping your practice succeed and grow. By setting clear goals and easily tracking your progress, you can ensure that your practice is always moving in the right direction.' +
      '<ul>' +
      '<li><b>Achieve Your Objectives: </b>Stay focused on your targets with clear and actionable insights.' +
      '<li><b>Foster Growth: </b>Use the data to identify opportunities for expansion and improvement.</li>' +
      '</ul>',
    title6: 'In Summary',
    description9: 'Dataplayer’s Advanced Analytics with AI is a game-changer for healthcare practices looking to make the most of their data. With our AI Assistant, you can analyse complex data effortlessly, forecast with greater accuracy, and make smarter decisions. The power of machine learning and AI is now at your fingertips, making it easier than ever to drive success and growth for your practice. Embrace the future of data analysis with Dataplayer and let our AI Assistant guide you to better, more informed decisions.',

  },
  {
    id: 6,
    text: 'Interactive Data Visualisations',
    mainTitle: 'Interactive Data Visualisations',
    img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/about/Group%209342.svg',
    mainDescription: 'See Your Data Like Never Before',
    desc: 'Transform raw data into interactive charts and graphs. These visualisations simplify data interpretation and support informed decision-making.',
    src: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/articles/visualisation.svg',
    title1: 'Why Interactive Data Visualisations Matters:',
    description3: 'In the realm of healthcare, making sense of raw data can be challenging. Dataplayer’s Interactive Data Visualisations turn complex data into clear, engaging charts and graphs that simplify interpretation. This feature is designed to help you understand your practice’s performance at a glance, making it easier to make informed decisions. Here’s why interactive visualisations are crucial:' +
      '<ul>' +
      '<li><b>Simplified Interpretation: </b> Transform complex data into easy-to-understand visuals.</li>' +
      '<li><b>Enhanced Clarity: </b> Get a clearer view of your practice’s performance with interactive charts and graphs.</li>' +
      '<li><b>Informed Decision-Making: </b> Use visual insights to make better decisions for your practice’s growth and efficiency.</li>' +
      '</ul>',
    title2: 'Transform Raw Data into Clear Visuals',
    description5: 'Raw data can be overwhelming, but Dataplayer’s Interactive Data Visualisations make it accessible and actionable. Our platform converts your data into dynamic charts and graphs that you can interact with to gain deeper insights.' +
      '<ul>' +
      '<li><b>Dynamic Charts: </b> View your data in various formats like bar charts, line graphs, and pie charts. These visuals help you quickly grasp trends, patterns, and anomalies.' +
      '<li><b>Customisation Options: </b> Adjust your charts to focus on the metrics that matter most to you. Customise the view to highlight specific data points or time periods.' +
      '</ul>',
    title3: 'Simplify Data Interpretation',
    description6: 'Understanding what your data is telling you shouldn’t be a struggle. With Dataplayer’s interactive visuals, you can explore your data effortlessly. Click, hover, and zoom to dive into details and uncover insights without the need for complex data analysis.' +
      '<ul>' +
      '<li><b>Interactive Features: </b> Use clickable elements and hover effects to explore data points and see additional details.' +
      '<li><b>Instant Insights: </b> Get immediate feedback and visual representation of your data, making it easier to identify key trends and areas for improvement.</li>' +
      '</ul>',
    title4: 'Support Informed Decision-Making',
    description7: 'Effective decision-making relies on clear, actionable information. Our interactive data visualisations provide you with the clarity you need to make decisions confidently. Whether you’re assessing patient flow, financial performance, or operational efficiency, the visuals give you a comprehensive view of your practice’s metrics.' +
      '<ul>' +
      '<li><b>Comprehensive Overview: </b>Get a complete picture of your practice’s performance with visual summaries of key metrics.' +
      '<li><b>Data-Driven Decisions:  </b>Use the insights from your visualisations to inform strategic decisions and drive improvements in your practice.</li>' +
      '</ul>',
    title5: 'How Interactive Data Visualisations Work',
    description8: 'Dataplayer’s interactive data tools are designed to be user-friendly and intuitive. Here’s how they enhance your experience:' +
      '<ul>' +
      '<li><b>User-Friendly Interface: </b> Easily navigate through interactive charts and graphs with a simple, intuitive interface.' +
      '<li><b>Real-Time Updates: </b>View live data and see your visuals update in real-time, ensuring you have the most current information at your fingertips.</li>' +
      '</ul>',
    title6: 'In Summary',
    description9: 'Dataplayer’s Interactive Data Visualisations turn complex data into clear, interactive charts and graphs, making it easier for you to understand and act on your practice’s performance. With simplified data interpretation and enhanced clarity, you can make informed decisions that drive your practice forward. Experience the power of interactive visualisations with Dataplayer and see your data in a whole new light.',

  },
]
export { WHAT_DP_CAN_DO }