/* eslint-disable max-len */
import { HMDG, PMGH } from '../Themes/Images'
const PARTNERS_ARTICLE_DATA = [
  {
    id: 9,
    slug: 'Dataplayer-and-PhysioBusinessGrowth',
    mainTitle: 'Dataplayer and Physio Business Growth',
    img: PMGH,
    mainDescription: 'Physio Business Growth offers tailored business coaching for physiotherapy practice owners seeking to streamline operations, boost profitability, and scale their business—ideal for teams of 3 or more.',
    desc: 'Transform raw data into interactive charts and graphs. These visualisations simplify data interpretation and support informed decision-making.',
    src: PMGH,
    // title1: 'Why Interactive Data Visualisations Matters:',
    description3: 'At <b>Dataplayer</b>, we are proud to partner with <b>Physio Business Growth</b>, a leading provider of bespoke business coaching for physiotherapy clinic owners. Together, we help physiotherapists streamline their operations, increase profitability, and scale their businesses.',
    title2: 'About Physio Business Growth',
    description5: 'Physio Business Growth specialises in tailored coaching services for physiotherapy clinic owners who want to grow their businesses. Whether you want to improve efficiency, boost your revenue, or expand your team, PBG provides the expertise to help you succeed. PBG works with businesses of all sizes—from small practices to larger clinics with bigger teams.',
    title3: 'Simplify Data Interpretation',
    description6: 'By focusing on areas like business management, leadership, marketing, and finances, PBG equips clinic owners with the tools they need to make smart decisions, drive performance, and achieve long-term success.',
    description7: 'Who Can Benefit from PBG’s Services?' +
      '<ul>' +
      '<li><b>Business Owners with Teams of 3+: </b>If you have a growing team, PBG can help you manage and scale effectively.' +
      '<li><b>Owners Ready to Grow: </b>If you want to increase your revenue or expand your clinic, PBG has the strategies to support your growth.</li>' +
      '<li><b>Physiotherapy Practices Seeking Efficiency: </b>PBG helps improve operations for smoother workflows and better patient experiences</li>' +
      '</ul>',
    title5: 'How Dataplayer and PBG Collaborate',
    description8: 'At Dataplayer, we know that data is key to making smart business decisions. That’s why we’re excited to work with Physio Business Growth. Together, we combine expert business coaching with powerful data insights to help physiotherapy clinic owners thrive.' +
      'Our Partnership Focuses on:' +
      '<ol>' +
      '<li><b>Data-Driven Decision Making: </b> With Dataplayer’s analytics platform, PBG can offer clients real-time insights to track key metrics and uncover areas for improvement. This helps clinic owners make better decisions to grow and increase profits.' +
      '<li><b>Optimising Business Operations: </b>By using Dataplayer’s platform, PBG can show how different business strategies affect clinic performance. This data helps owners make the right changes for better results.</li>' +
      '<li><b>Supporting Growth and Scaling: </b>PBG’s clients can use Dataplayer’s predictive analytics and performance tracking to make smarter decisions when scaling. Our platform’s detailed reports help clinic owners plan for growth, allocate resources effectively, and spot potential issues early.</li>' +
      '<li><b>Improving Financial Performance: </b>Dataplayer helps PBG’s clients understand their financial data, including revenue, expenses, and profit margins. This allows physiotherapy practices to make informed decisions that improve their financial health.</li>' +
      '</ol>',
    title6: 'Why Choose Physio Business Growth and Dataplayer?',
    description9: '<p>The partnership between <b>PBG’s business coaching</b> and <b>Dataplayer’s data analytics</b> offers physiotherapy practice owners a complete solution for business growth. Together, we provide practical tools and insights to help you streamline your operations, increase profits, and scale effectively.</p>' +
      '<p>With PBG, you get expert coaching to optimise your clinic, and with Dataplayer, you make decisions based on clear, actionable data. This combination of business coaching and data analytics ensures that your practice can thrive in a competitive market.</p>' +
      '<p>Visit Physio Business Growth’s website' + ' <a href="https://www.physiobusinessgrowth.com" target=_blank >www.physiobusinessgrowth.com</a> ' + 'to learn more.</p>',

  },
  {
    id: 12,
    slug: 'Dataplayer-and-HMDG',
    mainTitle: 'Dataplayer and HMDG',
    img: HMDG,
    mainDescription: 'HMDG is a specialist marketing agency for physiotherapy, chiropractic, and allied health practices, working with over 800 clinics across the UK and leading MSK brands. With a team of experienced clinic owners and marketing experts, HMDG offers ethical, high-quality marketing that’s transparent, friendly, and free from jargon.',
    desc: 'Transform raw data into interactive charts and graphs. These visualisations simplify data interpretation and support informed decision-making.',
    src: HMDG,
    description3: 'At <b>Dataplayer</b>,we’re excited to partner with <b>HMDG</b>, a leading marketing agency specialising in physiotherapy, chiropractic, and allied health services. With their extensive experience and impressive track record, HMDG is the trusted partner for healthcare clinics looking to improve their marketing strategies and grow their businesses.',
    title2: 'About HMDG',
    description5: '<p>HMDG is a specialist marketing agency that has worked with over 800 clinics across the UK, as well as some of the most influential brands in the MSK (musculoskeletal) industry. Their team consists of experienced clinic owners, marketing experts, designers, and branding professionals, which makes them uniquely equipped to support healthcare practices with their marketing needs.</p>'
      + '<p>Their mission is simple: to provide <b>ethical, high-quality marketing</b> that is transparent, approachable, and free from confusing jargon. HMDG believes in building strong, trusting relationships with its clients, making sure that every marketing decision is backed by a clear strategy and clear communication.</p>',
    title3: 'Who Can Benefit from HMDG’s Services?',
    description6: 'By focusing on areas like business management, leadership, marketing, and finances, PBG equips clinic owners with the tools they need to make smart decisions, drive performance, and achieve long-term success.',
    description7: 
      '<ul>' +
      '<li><b>Physiotherapy, Chiropractic, and Allied Health Clinics: </b> HMDG’s expertise is tailor-made for clinics in the healthcare sector, particularly those in physiotherapy, chiropractic, and other allied health services.' +
      '<li><b>Clinic Owners Looking to Grow: </b>If you’re ready to take your clinic to the next level and improve your marketing strategy, HMDG provides the tools and expertise to help you achieve your goals.</li>' +
      '<li><b>Practices Seeking Ethical, Transparent Marketing:</b>HMDG focuses on ethical marketing that helps build trust and clarity, making it easier for clinic owners to make the right decisions.</li>' +
      '</ul>',
    title5: 'How Dataplayer and HMDG Collaborate',
    description8: 'At Dataplayer, we believe in the power of data to drive business growth, and we’re thrilled to collaborate with HMDG. HMDG stands out in the healthcare marketing space because of its <b>ethical approach</b> and <b>expertise</b> in the physiotherapy, chiropractic, and allied health sectors. When combined with <b>Dataplayer’s data-driven insights</b>, healthcare practices can achieve greater success by improving their marketing strategies with clear, actionable data.' +
      '</br>' +
      'With HMDG, you get expert marketing and branding support from a team that understands your clinic’s needs. And with Dataplayer, you get access to powerful analytics that drive smarter, data-backed marketing decisions.' +
      '</br>' +
      '<a href="https://hmdg.co.uk/" target=_blank ><b>Visit HMDG’s website</b></a> to learn more about how they can help your clinic thrive.',
  },
]

export default PARTNERS_ARTICLE_DATA