/* eslint-disable react/no-unescaped-entities */
/* eslint-disable line-comment-position */
/* eslint-disable no-var */

import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import Layout from './Layout'
const NewHome = React.lazy(() => import('./Pages/NewHome'))
const Pricing = React.lazy(() => import('./Pages/NewPricing'))
const Security = React.lazy(() => import('./Pages/Security'))
const TermsAndConditions = React.lazy(() => import('./Pages/TermsAndCondtion'))
const WhyDataplayer = React.lazy(() => import('./Pages/WhyDataplayer'))
const ContactUs = React.lazy(() => import('./Pages/ContactUs'))
const CookiesPage = React.lazy(() => import('./Pages/Cookies'))
const FAQ = React.lazy(() => import('./Pages/FAQ'))
const HelpCentre = React.lazy(() => import('./Pages/HelpCentre'))
const Demo = React.lazy(() => import('./Pages/Demo'))
const Testimonials = React.lazy(() => import('./Pages/Testimonials'))
const Partners = React.lazy(() => import('./Pages/PartnersWholeScreen'))

import { ZohoChatScipt, FacebookPixelCode, GoogleAnlV3 } from './Utils/Scripts'
import './App.css'
import CookieConsent from 'react-cookie-consent'
import cookie from 'react-cookies'
import Text from './Components/Text'
import { colors } from './Themes'
import useWindowDimension from './Utils/useWindowDimension'
import CircularIndeterminate from './Components/CircularLoad/CircularLoad'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Podiatry from './Pages/Podiatry'
import TherapyEXPO from './Pages/TherapyEXPO'
import Article from './Components/Article'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import PartnersArticle from './Components/Article/PartnersArticle'

const containtainerStyleCookies = {
  background: 'rgb(255, 255, 255)',
  color: 'white',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  left: 20,
  position: 'fixed',
  maxWidth: 330,
  // height: 303,
  zIndex: 999,
  padding: 16,
  bottom: 34,
  borderRadius: 5,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  marginBottom: 34,
  // flexDirection: 'column',
  alignItems: 'start'
}

const containtainerStyleCookiesMobile = {
  background: 'rgb(255, 255, 255)',
  color: 'white',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  left: 34,
  position: 'fixed',
  maxWidth: 314,
  // width: '75%',
  // marginInline: 'auto',
  zIndex: 999,
  padding: 16,
  bottom: 34,
  borderRadius: 5,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  marginBottom: 34
}

const containtainerStyleCookiesIpad = {
  background: 'rgb(255, 255, 255)',
  color: 'white',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  left: 20,
  position: 'fixed',
  maxWidth: 400,
  zIndex: 999,
  padding: 16,
  bottom: 34,
  borderRadius: 5,
  boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
  marginBottom: 34,
  // flexDirection: 'column'
}

const AppNew = () => {
  const { width } = useWindowDimension()
  const [allowScritps, setAllowScripts] = useState(cookie.loadAll().CookieConsent)

  const declineButtonStyle = {
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#293E57',
    border: '1px solid #293E57',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: 'rgba(49, 65, 85, 1)',
    transition: '0.5s',
    height: width > 760 ? 60 : 38,
    width: width > 760 ? '50%' : '45%',
    fontSize: width > 760 ? 18 : 16,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0 0',
    '&:hover': {
      backgroundColor: '#EE4352',
      color: 'rgba(255, 255, 255, 1)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#EE4352',
    },
  }
  const acceptButtonStyle = {
    border: '0px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    color: 'white',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0)',
    transition: '0.5s',
    height: width > 760 ? 60 : 38,
    width: width > 760 ? '50%' : '45%',
    fontSize: width > 760 ? 18 : 16,
    margin: '15px 0 0',
    '&:hover': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(152, 35, 92, 1)',
      border: '1px solid rgba(152, 35, 92, 1)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: 'rgba(152, 35, 92, 1)',
    },
  }

  function waitForElm(selector) {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector))
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector))
          observer.disconnect()
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true
      })
    })
  }

  const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false)

    useEffect(() => {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 600) {
          setShowTopBtn(true)
        } else {
          setShowTopBtn(false)
        }
      })
      return () => {
        setShowTopBtn(false)
      }
    }, [])

    const goToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return (
      <div className="top-to-btm">
        {' '}
        {showTopBtn && (
          <ExpandLessIcon
            className="icon-position icon-style"
            onClick={goToTop}
          />
        )}{' '}
      </div>
    )
  }

  // const ScrollToTopPageChange = () => {
  //   // Extracts pathname property(key) from an object
  //   const { pathname } = useLocation()

  //   // Automatically scrolls to top whenever pathname changes
  //   useEffect(() => {
  //     window.scrollTo(0, 0)
  //   }, [pathname])
  // }

  const widthh = width < 700

  const checkForElement = async () => {
    const elm = await waitForElm('.zsiq_floatmain')
    elm.setAttribute('style', 'z-index: 1 !important')
  }

  const [selectedArticleId, setSelectedArticleId] = useState(null)

  const isArticle = selectedArticleId !== null

  useEffect(() => {
    if (allowScritps === 'true') {
      ZohoChatScipt()
      FacebookPixelCode()
      // GoogleAnl()
      // GoogleAnlV2(),
      GoogleAnlV3(),
      checkForElement()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowScritps])



  return (
    <>
      <React.Suspense fallback={<CircularIndeterminate />}>
        {/* <ScrollToTop /> */}
        <Layout isArticle={isArticle}>
          <Switch>
            <Route exact path="/" ><NewHome  selectedArticleId={selectedArticleId} setSelectedArticleId={setSelectedArticleId}/></Route>
            <Route exact path="/pricing"><Pricing /></Route>
            <Route exact path="/terms"><TermsAndConditions /></Route>
            <Route exact path="/about"><WhyDataplayer /></Route>
            <Route exact path="/cookies"><CookiesPage /></Route>
            <Route exact path="/privacyPolicy"><PrivacyPolicy /></Route>
            <Route exact path="/contactus"><ContactUs /></Route>
            <Route exact path="/security"><Security /></Route>
            <Route exact path="/faq"><FAQ /></Route>
            <Route exact path="/podiatry2023"><Podiatry /></Route>
            <Route exact path="/therapyexpo"><TherapyEXPO /></Route>
            <Route exact path="/help-centre"><HelpCentre /></Route>
            <Route exact path="/demo"><Demo /></Route>
            <Route exact path="/testimonials"><Testimonials /></Route>
            <Route exact path="/partners"><Partners selectedArticleId={selectedArticleId} setSelectedArticleId={setSelectedArticleId} /></Route>
            <Route exact path="/partners/:slug"><PartnersArticle/></Route>
            <Route exact path="/article/:articleId"><Article /></Route>
            <Redirect to="/" />
          </Switch>
          <div id='zsiqwidget'></div>
        </Layout>
      </React.Suspense>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText='Cancel'
        style={(width > 600 && width < 900) ? containtainerStyleCookiesIpad : width > 900 ? containtainerStyleCookies : containtainerStyleCookiesMobile}
        contentStyle={{ margin: 0 }}
        buttonWrapperClasses='cookiesButtonWrapper'
        // flipButtons={width <= 600}
        buttonStyle={acceptButtonStyle}
        declineButtonStyle={declineButtonStyle}
        expires={150}
        onAccept={() => {
          setAllowScripts('true')
        }}
        enableDeclineButton
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: widthh ? '5px' : 19, }}>
          {/* <img style={{ width: 50, height: 50, objectFit: 'contain' }}  src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/cookies.svg' alt='cookies' /> */}
          <Text
            color={colors.primary}
            size='subnote'>
            We use cookies to enhance your experience. Please choose whether to accept cookies. For details, view our<span
              onClick={() => window.open('/cookies')}
              style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 5, color: colors.primary }}>
              Cookie Policy.</span>
          </Text>
        </div>
      </CookieConsent>
      <ScrollToTop />
    </>
  )
}

export default AppNew